@import "./_sidebar_vertical";
@import "./_sidebar_slim";
@import "./_sidebar_reveal";
@import "./_sidebar_drawer";
@import "./_sidebar_horizontal";
@import "./_sidebar_compact";
@import "./_sidebar_themes";

.layout-menu {
  ul {
    a {
      padding: 0.7rem 1rem;
      .layout-submenu-toggler {
        display: block;
      }
    }
    ul {
      overflow: hidden;
      border-radius: var(--border-radius);

      li {
        a {
          padding-left: 2.5rem;
        }

        li {
          a {
            padding-left: 3rem;
          }

          li {
            a {
              padding-left: 3.5rem;
            }

            li {
              a {
                padding-left: 4rem;
              }

              li {
                a {
                  padding-left: 5.5rem;
                }

                li {
                  a {
                    padding-left: 5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.layout-menu-container {
  overflow: hidden;
  background-color: var(--tactiq-sidebar-bg-color-alt);
}

.sidebar-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;
  border-bottom: var(--d-menu-separator-border);
  .logo {
    width: 100%;
    border: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0;

    .app-name {
      display: none;
    }
  }

  .layout-sidebar-anchor {
    display: none;
    flex-shrink: 0;
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 50%;
    border: var(--d-menu-separator-border);
    border-width: 2px;
    background-color: transparent;
    outline: none;
    transition:
      background-color var(--transition-duration),
      transform 0.3s;
  }
}

.sidebar-footer {
  background-color: var(--tactiq-sidebar-bg-color-dark);
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--tactiq-sidebar-text-color);
  font-size: 12px;
  p {
    margin: 0.5rem 0 0;
  }
}
