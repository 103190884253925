.layout-footer {
  background: var(--surface-card);
  height: 4rem;
  border-top: 1px solid var(--surface-border);
  border-bottom: 1px solid var(--surface-border);
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer-logo-container {
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }

    .footer-app-name {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.2px;
      margin-left: 0.5rem;
    }
  }

  .footer-copyright {
    font-size: 0.875rem;
    color: var(--text-color-secondary);
  }
}
