.overlay-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--tactiq-surface-ground);
  z-index: 100;
  width: 100%;
  height: 100%;
}
