@media screen and (min-width: $breakpoint) {
  .layout-wrapper {
    &.layout-reveal {
      .topbar-left {
        padding-left: 3.5rem;
        .horizontal-logo {
          display: none;
        }

        .menu-button {
          display: none;
        }

        .topbar-separator {
          display: none;
        }
      }

      .layout-sidebar {
        height: 100%;
        top: 0;
        transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
        transform: translateX(-12rem);
        z-index: 999;

        .layout-menu {
          .layout-root-menuitem {
            > .layout-menuitem-root-text {
              > span {
                margin-right: auto;
              }

              > .layout-menuitem-root-icon {
                display: block;
                margin-right: 0.125rem;
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
              .layout-menuitem-icon {
                order: 1;
                margin-right: 0.5rem;
                font-size: 1.25rem;
                width: auto;
                font-size: 16px;
              }
              .layout-menuitem-text {
                order: 2;
              }
              .layout-submenu-toggler {
                order: 3;
                // display: none;
              }
            }
          }
        }
      }

      &.layout-reveal {
        .layout-content-wrapper {
          margin-left: 3.25rem;
          transition: margin-left 0.3s cubic-bezier(0, 0, 0.2, 1);
        }
      }

      &.layout-sidebar-active {
        .layout-sidebar {
          transform: translateX(0);
          .layout-menu-container {
            overflow: auto;
          }

          .sidebar-header {
            justify-content: space-between;
            padding: 1rem;
            .logo {
              width: 100%;
              border: none;
              justify-content: flex-start;

              .app-name {
                display: inline-block;
              }
            }

            .layout-sidebar-anchor {
              display: flex;
              flex-shrink: 0;
            }
          }

          .layout-menu {
            .layout-root-menuitem {
              > .layout-menuitem-root-text {
                font-size: 0.857rem;
                text-transform: uppercase;
                font-weight: 700;
                padding: 0.5rem 0 1rem 0;
                > .layout-menuitem-root-icon {
                  display: none;
                }
              }
            }
          }
        }
      }

      &.layout-sidebar-anchored {
        .layout-topbar {
          .topbar-left {
            padding-left: 16rem;
          }
        }
        .sidebar-header {
          .layout-sidebar-anchor {
            background-color: var(--primary-light-color);
            border: 2px solid var(--primary-color);
          }
        }

        .layout-content-wrapper {
          margin-left: 16rem;
        }
      }
    }
  }
}
