// @import "assets/layout/styles/theme/theme-light/tactiq/theme.scss";
@import "assets/themes/tactiq/theme.scss";
@import "assets/layout/styles/layout/layout.scss";
@import "assets/common/dialog";
@import "assets/common/overlay-content";

@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";

:root {
  // Colors
  --primary-color: #1d5163;
  --tactiq-primary-color: #1d5163;
  --tactiq-danger-color: #d9342b;
  --tactiq-primary-dark-color: #184452;
  --tactiq-primary-darker-color: #043d48;
  --tactiq-primary-text-color: #ffffff;
  --tactiq-surface-ground: #ffffff; // Page content background color
  --tactiq-surface-overlay: #f8fcfd; // Topbar background color
  --tactiq-sidebar-bg-color-dark: #053740; // Sidebar header background color
  --tactiq-sidebar-bg-color-alt: #194555; // Sidebar content background color
  --tactiq-sidebar-border: 0 none;
  --tactiq-app-name-color: #ffffff;
  --tactiq-separator-border: 1px solid #dfe7ef;
  --tactiq-menu-separator-border: 1px solid #194555;
  --tactiq-menuitem-root-text-color: rgba(255, 255, 255, 0.6);
  --tactiq-menuitem-text-color: #e9f7f8;
  --tactiq-sidebar-text-color: #ffffff;
  --tactiq-menuitem-hover-bg: rgba(255, 255, 255, 0.1);
  --tactiq-menuitem-active-bg: #053740;
  --tactiq-menuitem-text-active-color: #e9f7f8;
  --tactiq-menuitem-focus-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
  --tactiq-input-color: #343434;
  --tactiq-input-color-secondary: #6c757d;
  // Font
  --font-family: "Source Sans Pro", sans-serif;
  --button-font-size: 14px;
  --mobile-link-active-color: #00abc8;
  --text-color-secondary: #495057;
  // Layout
  --layout-topbar-height: 56px;
  --layout-mobile-topbar-height: 48px;
  --layout-mobile-bottombar-height: 52px;
  --layout-mobile-max-width: 991px;
}

.layout-sidebar-tactiq {
  --d-sidebar-bg-color: var(--tactiq-sidebar-bg-color-dark);
  --d-sidebar-bg-color-alt: var(--tactiq-sidebar-bg-color-alt);
  --d-sidebar-border: var(--tactiq-sidebar-border);
  --d-app-name-color: var(--tactiq-app-name-color);
  --d-menu-separator-border: var(--tactiq-menu-separator-border);
  --d-menuitem-root-text-color: var(--tactiq-menuitem-root-text-color);
  --d-menuitem-text-color: var(--tactiq-menuitem-text-color);
  --d-menuitem-hover-bg: var(--tactiq-menuitem-hover-bg);
  --d-menuitem-active-bg: var(--tactiq-menuitem-active-bg);
  --d-menuitem-text-active-color: var(--tactiq-menuitem-text-active-color);
  --d-menuitem-focus-shadow: var(--tactiq-menuitem-focus-shadow);
}

.twx-border-bottom {
  border-bottom: var(--tactiq-separator-border);
}
.twx-danger-color {
  color: var(--tactiq-danger-color);
}
.twx-text-secondary {
  color: var(--text-color-secondary);
}

.twx-box-shadow {
  box-shadow: 0px 2px 12px 0px #0000001a;
}

.w-400 {
  width: 400px;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}

p-dropdown .p-dropdown {
  height: 39px;
  align-items: center;
}

.twx-input {
  height: 55px;

  input {
    height: 39px;
    width: 100%;
  }

  .p-error {
    float: left;
    margin-top: 2px;
  }

  .p-password {
    width: 100%;
    input {
      padding-left: 2.25rem;
    }
  }
}

.p-autocomplete-items {
  padding: 0.65rem;
}

.p-input-icon-left {
  .p-dropdown-label,
  p-autocomplete input {
    height: 39px;
    width: 100%;
    padding-left: 2.25rem;
  }

  p-inputMask input {
    height: 39px;
    width: 100%;
    padding-left: 2.25rem;
  }

  p-calendar .p-calendar {
    width: 100%;

    & > input {
      height: 39px;
      width: 100%;
      padding-left: 2.25rem;
    }
  }
}

.p-dropdown,
.p-autocomplete {
  width: 100%;
}

.p-dialog .p-dialog-content:last-of-type {
  border-radius: 6px;
}

.p-autocomplete-input .p-autocomplete-dd-input {
  border-right: none;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  color: var(--tactiq-input-color-secondary);
  background-color: transparent;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}
.alert-acknoledged {
  color: #1da750;
  font-size: 14px;
}

.no-scroll {
  overflow: hidden;
}
