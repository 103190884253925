* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: var(--surface-ground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}
