// Theme Specific Variables
$primaryColor: #1d5163;
$primaryDarkColor: #184452;
$primaryDarkerColor: #184452;
$primaryTextColor: #ffffff;

$mobile-max-width: 991px;

// $colors: (
//   "blue": #2196f3,
//   "green": #4caf50,
//   "yellow": #fbc02d,
//   "cyan": #00bcd4,
//   "pink": #e91e63,
//   "indigo": #3f51b5,
//   "teal": #009688,
//   "orange": #f57c00,
//   "bluegray": #607d8b,
//   "purple": #9c27b0,
//   "red": #ff4032,
//   "primary": var(--tactiq-primary-color),
// );

// Mandatory Designer Variables
@import "./variables/general";
@import "./variables/form";
@import "./variables/button";
@import "./variables/panel";
@import "./variables/_data";
@import "./variables/_overlay";
@import "./variables/_message";
@import "./variables/_menu";
@import "./variables/_media";
@import "./variables/_misc";

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #343434;
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: var(--tactiq-surface-ground);
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  color-scheme: light;
}
