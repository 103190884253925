$scale: 14px !default;
$breakpoint: 992px !default;
$sidebarShadow:
  0 2px 4px -1px rgba(0, 0, 0, 0.2),
  0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;

@import "./_fonts";
@import "./_utils";
@import "./_typography";
@import "./_animation";
@import "./_main";
@import "./_topbar";
@import "./_search";
@import "./sidebar/_sidebar";
@import "./_content";
@import "./_breadcrumb";
@import "./_footer";
@import "./_config";
@import "./_responsive";
