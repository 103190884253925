.search-container {
  background: var(--surface-overlay);
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding: 2rem;
  border-radius: var(--border-radius);
  position: relative;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: var(--surface-border);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: all;

  input {
    appearance: none;
    font-size: 1.5rem;
    text-indent: 2.5rem;
    padding: 0.5rem;
    width: 100%;
  }

  i {
    color: var(--text-color-secondary);
    width: 2rem;
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    margin-top: -0.75rem;
    margin-left: 1rem;
  }
}
