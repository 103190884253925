.layout-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  transition: margin-left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-content {
  padding-top: var(--layout-topbar-height);
  flex: 1 1 auto;
}
