.p-inputtext {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: $inputTextFontSize;
  color: $inputTextColor;
  background: $inputBg;
  padding: $inputPadding;
  border: $inputBorder;
  transition: $formElementTransition;
  appearance: none;
  border-radius: $borderRadius;

  &:enabled:hover {
    border-color: $inputHoverBorderColor;
  }

  &:enabled:focus {
    @include focused-input();
  }

  &.ng-dirty.ng-invalid {
    @include invalid-input();
  }

  &.p-inputtext-sm {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }

  &.p-inputtext-lg {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}

.p-float-label > label {
  left: nth($inputPadding, 2);
  color: $inputPlaceholderTextColor;
  transition-duration: $transitionDuration;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: $inputErrorBorderColor;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: nth($inputPadding, 2);
  color: $inputIconColor !important;
  margin-top: 0 !important;
  top: 12px !important;
}

.p-input-icon-left > .p-inputtext {
  padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
  left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}
.p-input-icon-left.disabled i.pi {
  opacity: 0.5;
}
// .p-icon-field-left .p-input-icon:first-of-type {
//     left: 0.75rem;
//     color: #94a3b8;
// }
// .p-icon-field .p-input-icon {
//     position: absolute;
//     top: 50%;
//     margin-top: -0.5rem;
// }
// .p-inputtext {
//     border-radius: var(--border-radius);
//     color: var(--tactiq-input-color) !important;
//     padding: 9px;
//   }
//   .p-input-icon-left > .p-inputtext {
//     padding-left: 35px;
//   }
//   .p-input-icon-left > .p-icon-wrapper.p-icon,
//   .p-input-icon-left > i:first-of-type {
//       left: 12px;
//       color: #6c757d;
// }
.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: nth($inputPadding, 2);
  color: $inputIconColor;
}

.p-input-icon-right > .p-inputtext {
  padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

@include placeholder {
  color: $inputPlaceholderTextColor;
}

.p-input-filled {
  .p-inputtext {
    background-color: $inputFilledBg;

    &:enabled:hover {
      background-color: $inputFilledHoverBg;
    }

    &:enabled:focus {
      background-color: $inputFilledFocusBg;
    }
  }
}

.p-inputtext-sm {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }
}

.p-inputtext-lg {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}
